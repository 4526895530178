



.icon-halo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: v-bind(haloHeightProperty);
    width: v-bind(haloWidthProperty);
    flex: v-bind(haloFlexProperty);
    min-height: v-bind(haloHeightProperty);
    min-width: v-bind(haloWidthProperty);
    border-radius: v-bind(haloBorderRadiusProperty);
    background-color: v-bind(haloBackgroundColorProperty);

    .we-icon {
        height: v-bind(heightProperty);
        width: v-bind(widthProperty);
        flex: v-bind(flexProperty);
        background-color: v-bind(colorProperty);
        -webkit-mask: v-bind(finalIconMaskProperty);
        mask: v-bind(finalIconMaskProperty);
        -webkit-mask-size: contain;
        mask-size: contain;
    }
}

